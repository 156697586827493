define("discourse/plugins/Moderatori Plugin/discourse/controllers/admin-plugins-moderatori", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _controller, _object, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPLuginsModeratoriController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "showText", [_tracking.tracked], function () {
      return false;
    }))();
    #showText = (() => (dt7948.i(this, "showText"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "createUserText", [_tracking.tracked], function () {
      return "";
    }))();
    #createUserText = (() => (dt7948.i(this, "createUserText"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "editEmailText", [_tracking.tracked], function () {
      return "";
    }))();
    #editEmailText = (() => (dt7948.i(this, "editEmailText"), void 0))();
    showTentacle() {
      this.showText = !this.showText;
      console.log("PLUGIN");
      (0, _ajax.ajax)("/moderatori", {}).then(uploads => console.log(uploads));
    }
    static #_4 = (() => dt7948.n(this.prototype, "showTentacle", [_object.action]))();
    createUser() {
      let new_email = document.getElementById("new_email_create").value;
      let new_username = document.getElementById("new_username_create").value;
      let new_password = document.getElementById("new_password_create").value;
      if (new_email == "") {
        this.createUserText = "Inserisci email";
        return;
      } else if (new_username == "") {
        this.createUserText = "Inserisci username";
        return;
      } else if (new_password == "") {
        this.createUserText = "Inserisci password";
        return;
      } else if (new_password.length < 12) {
        console.log(new_password);
        this.createUserText = "Lunghezza minima password: 12";
        return;
      } else {
        $.ajax({
          type: "POST",
          url: "/custom_create_user",
          data: {
            new_email: new_email,
            new_username: new_username,
            new_password: new_password
          },
          success: response => {
            this.createUserText = response["text"].toString();
          },
          error: function (xhr) {
            console.error("Errore", xhr);
          }
        });
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "createUser", [_object.action]))();
    editEmail() {
      let old_email = document.getElementById("old_email_edit").value;
      let new_email = document.getElementById("new_email_edit").value;
      if (old_email == "") {
        this.editEmailText = "Inserisci vecchia email";
        return;
      } else if (new_email == "") {
        this.editEmailText = "Inserisci nuova email";
        return;
      } else if (old_email == new_email) {
        this.editEmailText = "Le email sono uguali";
        return;
      } else {
        $.ajax({
          type: "POST",
          url: "/custom_update_email",
          data: {
            old_email: old_email,
            new_email: new_email
          },
          success: response => {
            this.editEmailText = response["text"].toString();
          },
          error: function (xhr) {
            console.error("Errore", xhr);
          }
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "editEmail", [_object.action]))();
  }
  _exports.default = AdminPLuginsModeratoriController;
});