define("discourse/plugins/Moderatori Plugin/discourse/components/modal/custom-palette", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-pointer-down-event-binding }}
  <DModal
    {{on "keydown" this.keyDown}}
    {{on "mousedown" this.mouseDown}}
    @closeModal={{@closeModal}}
    @title="Scegli evidenziatore"
    @bodyClass="insert-link"
    class="insert-hyperlink-modal"
  >
    <:body>
  
      <DButton
        @action={{fn this.setColor "yellow"}}
        @label="COLOR"
        style="background-color: yellow; color: yellow;"
      />
  
      <DButton
        @action={{fn this.setColor "orange"}}
        @label="COLOR"
        style="background-color: orange; color: orange;"
      />
  
      <DButton
        @action={{fn this.setColor "red"}}
        @label="COLOR"
        style="background-color: red; color: red;"
      />
  
      <DButton
        @action={{fn this.setColor "green"}}
        @label="COLOR"
        style="background-color: green; color: green;"
      />
  
      <DButton
        @action={{fn this.setColor "blue"}}
        @label="COLOR"
        style="background-color: blue; color: blue;"
      />
  
    </:body>
  
  
  </DModal>
  */
  {
    "id": "Dzmny6mB",
    "block": "[[[8,[39,0],[[24,0,\"insert-hyperlink-modal\"],[4,[38,1],[\"keydown\",[30,0,[\"keyDown\"]]],null],[4,[38,1],[\"mousedown\",[30,0,[\"mouseDown\"]]],null]],[[\"@closeModal\",\"@title\",\"@bodyClass\"],[[30,1],\"Scegli evidenziatore\",\"insert-link\"]],[[\"body\"],[[[[1,\"\\n\\n    \"],[8,[39,3],[[24,5,\"background-color: yellow; color: yellow;\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0,[\"setColor\"]],\"yellow\"],null],\"COLOR\"]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,5,\"background-color: orange; color: orange;\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0,[\"setColor\"]],\"orange\"],null],\"COLOR\"]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,5,\"background-color: red; color: red;\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0,[\"setColor\"]],\"red\"],null],\"COLOR\"]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,5,\"background-color: green; color: green;\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0,[\"setColor\"]],\"green\"],null],\"COLOR\"]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,5,\"background-color: blue; color: blue;\"]],[[\"@action\",\"@label\"],[[28,[37,4],[[30,0,[\"setColor\"]],\"blue\"],null],\"COLOR\"]],null],[1,\"\\n\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"on\",\":body\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/Moderatori Plugin/discourse/components/modal/custom-palette.hbs",
    "isStrictMode": false
  });
  class CustomPalette extends _component2.default {
    _debounced;
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this._debounced);
    }

    // KEEP THIS FUNCTION
    keyDown(event) {}

    // KEEP THIS FUNCTION
    static #_ = (() => dt7948.n(this.prototype, "keyDown", [_object.action]))();
    mouseDown(event) {}
    static #_2 = (() => dt7948.n(this.prototype, "mouseDown", [_object.action]))();
    setColor(color) {
      this.args.model.toolbarEvent.addText(color);
      this.args.closeModal();
    }
    static #_3 = (() => dt7948.n(this.prototype, "setColor", [_object.action]))();
  }
  _exports.default = CustomPalette;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomPalette);
});